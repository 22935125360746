<template>
  <div>
    <div class="container space-2 space-top-3">
      <!-- logo -->
      <!-- <div class="space-1 text-center">
          <a href="https://www.openapihub.com/" target="_blank" class="navbar-brand" aria-label="OpenAPIHub by beNovelty">
            <img src="@/assets/svg/logos/logo.svg" alt="OpenAPIHub">
          </a>
        </div> -->
      <!-- content -->

      <div class="w-lg-90 mx-auto text-center mb-7">
        <h2>Thanks for Your Interest in apidays Hong Kong</h2>
        <p>Let's enjoy the full replay of apidays Hong Kong 2020-2023 now!</p>

        <!-- 2023 -->
        <div class="position-relative bg-navy rounded my-7 p-5">
          <img class="img-fluid max-w-11rem mx-auto mb-3" src="@/assets/svg/illustrations/popper.svg">
          <h3 class="text-white">Apidays Connect Hong Kong 2023 Replay</h3>
          <div class="row my-7">
            <div class="col-12 col-md-4 mb-4 mb-lg-0">
              <a class="card card-bg-light h-100 shadow-soft overflow-hidden transition-3d-hover" href="https://www.youtube.com/watch?v=qoBA5RS4ZH4&list=PLTGS2R8AlCactn9it-gwpJrhULUZ0HzQj&pp=iAQB" target="_blank">
                <div class="py-4 px-2">
                  <h4 class="mb-3">Day 1 Main Stage Videos</h4>
                  <span class="font-weight-bold">Watch Now <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </a>
            </div>
            <div class="col-12 col-md-4 mb-4 mb-lg-0">
              <a class="card card-bg-light h-100 shadow-soft overflow-hidden transition-3d-hover" href="https://www.youtube.com/watch?v=iMeBhVGPQEw&list=PLTGS2R8AlCafrp0WpCO_X5JfOkzI0ibEs&pp=iAQB" target="_blank">
                <div class="py-4 px-2">
                  <h4 class="mb-3">Day 2 Stage 1 Videos</h4>
                  <span class="font-weight-bold">Watch Now <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </a>
            </div>
            <div class="col-12 col-md-4 mb-4 mb-lg-0">
              <a class="card card-bg-light h-100 shadow-soft overflow-hidden transition-3d-hover" href="https://www.youtube.com/watch?v=g0TNPjB_GvU&list=PLTGS2R8AlCacg5MbR8FSkeBxP-4y9CiYn&pp=iAQB" target="_blank">
                <div class="py-4 px-2">
                  <h4 class="mb-3">Day 2 Stage 2 Videos</h4>
                  <span class="font-weight-bold">Watch Now <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </a>
            </div>
          </div>
          <p class="text-center small text-white">For the detailed event agenda, please refer to <a href="https://www.apidays.hk/2023" class="text-white" style="text-decoration:underline" target="'_blank">here</a>.</p>
        </div>

        <!-- 2020 - 2022 -->
        <div class="position-relative rounded my-7 p-5" style="background-color:#f5f9fc;">
          <img class="img-fluid max-w-19rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
          <h3 class="mb-4">Apidays Hong Kong 2020 — 2022 Replay</h3>
          <p>The following resources are now available on OpenAPIHub—our one-stop API Community Platform. Please log in to the platform, or register as an OpenAPIHub member for FREE to watch the resource now!</p>
          <div class="row my-7 text-left">
            <div class="col-md-4 mb-4 mb-md-0">
              <a class="card h-100 transition-3d-hover" href="https://hub.openapihub.com/member/community-updates/20220824-Apidays-Hong-Kong-2022/resources?_gl=1*7rjo2e*_gcl_au*MTg5NDk5MDUwNC4xNjk1MzQ5ODkx" target="_blank">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/thumbnails/apidays-live-hongkong-2022.jpg">
                  <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                      <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                    </svg>
                  </figure>
                </div>
                <div class="card-body">
                  <div class="mb-5">
                    <h4>Apidays Live Hong Kong 2022</h4>
                    <p class="font-size-1">API-First Digital Transformation & Platform Economy</p>
                  </div>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Watch Replay Now <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </a>
            </div>
            <div class="col-md-4 mb-4 mb-md-0">
              <a class="card h-100 transition-3d-hover" href="https://hub.openapihub.com/member/community-updates/20210825-Apidays-Live-Hong-Kong-2021/resources?_gl=1*15qusms*_gcl_au*MTg5NDk5MDUwNC4xNjk1MzQ5ODkx" target="_blank">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/thumbnails/apidays-live-hongkong-2021.jpg">
                  <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                      <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                    </svg>
                  </figure>
                </div>
                <div class="card-body">
                  <div class="mb-5">
                    <h4>Apidays Live Hong Kong 2021</h4>
                    <p class="font-size-1">API Ecosystem & Data Interchange</p>
                  </div>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Watch Replay Now <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </a>
            </div>
            <div class="col-md-4 mb-0">
              <a class="card h-100 transition-3d-hover" href="https://hub.openapihub.com/member/community-updates/20201008-Apidays-Live-Hong-Kong-2020/resources?_gl=1*15qusms*_gcl_au*MTg5NDk5MDUwNC4xNjk1MzQ5ODkx" target="_blank">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/thumbnails/apidays-live-hongkong.jpg">
                  <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                      <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                    </svg>
                  </figure>
                </div>
                <div class="card-body">
                  <div class="mb-5">
                    <h4>Apidays Live Hong Kong 2020</h4>
                    <p class="font-size-1">The Open API Economy: Finance-as-a-Service &amp; API Ecosystems</p>
                  </div>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Watch Replay Now <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </a>
            </div>
          </div>

        </div>

      </div>

      <hr>

      <!-- Other words -->
      <div class="space-1 text-center">
        <p>If you are signed up for any other resources, please remember to check with both of your mailbox or "junk mail" folder.<br>And don't forget to add <a href="mailto:patrick@apidays.hk">patrick@apidays.hk</a> to your contact list so we don't end up in your junk mail!
        </p>
        <p>We'll keep you in loop on the latest happenings here at <a href="https://www.apidays.hk/" target="_blank">apidays.hk</a> or <a href="https://www.openapihub.com/" target="_blank">OpenAPIHub by beNovelty</a>.<br>Stay tuned for our upcoming events!</p>
      </div>

      <!-- footer -->
      <!-- <footer class="space-2 space-top-3 text-center">
          <a href="https://www.openapihub.com/" target="_blank" class="d-inline-flex align-items-center mb-3" aria-label="OpenAPIHub by beNovelty">
            <img class="brand" src="@/assets/svg/logos/logo.svg" alt="Logo">
          </a>
          <p class="font-size-1 mb-1"><span v-html="$t('SignUpConfirmationPage.Footer.Description')" /></p>
          <p class="small text-muted">© {{ new Date().getFullYear() }} {{ $t('SignUpConfirmationPage.Footer.Copyright') }}</p>
        </footer> -->
    </div>
  </div>
</template>

<script>
import 'bootstrap'

export default {
  name: 'SignupConfirmation',
  data() {
    return {
      registerCards: [
        {
          title: 'SignUpConfirmationPage.Card1.Title',
          img: 'learn-api',
          button: 'SignUpConfirmationPage.Card1.Button',
          url: 'https://www.openapihub.com/academy/'
        },
        {
          title: 'SignUpConfirmationPage.Card2.Title',
          img: 'api-product',
          button: 'SignUpConfirmationPage.Card2.Button',
          url: 'https://www.openapihub.com/api-provider'
        },
        {
          title: 'SignUpConfirmationPage.Card3.Title',
          img: 'api-hub',
          button: 'SignUpConfirmationPage.Card3.Button',
          url: 'https://www.openapihub.com/enterprise-api-hub'
        }
      ]
    }
  }
}
</script>

  <style lang="scss" scoped>

  </style>

